<template>
    <div class="app">
        <el-table :data="list" @selection-change="handleSelectionChange">
            <el-table-column prop="keyGroup" label="分组"></el-table-column>
            <el-table-column prop="keyName" label="key"></el-table-column>
            <el-table-column prop="keyText" label="key名字"></el-table-column>
            <el-table-column prop="configValue" label="值"></el-table-column>
            <el-table-column prop="ordering" label="排序"></el-table-column>
            <el-table-column prop="createDate" label="日期"></el-table-column>
            <el-table-column prop="code" label="唯一码"></el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
            <el-pagination
                    @current-change="currentChange"
                    @size-change="handleSizeChange"
                    background
                    :total="totalData"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    layout="sizes, prev, pager, next, total, jumper"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        components: {},
        props: {
            group: String,
        },
        data() {
            return {
                list: [],
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    keyGroup:'',
                },
            };
        },
        methods: {
            orderDate(obj) {
                if (obj) {
                    this.where.startDate = obj[0];
                    this.where.endDate = obj[1];
                }
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            getList() {
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.where.keyGroup = this.group;
                this.$post(this.$api.systemConfigList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                });
            },
            currentChange(e) {
                this.currentPage = e;
                this.getList();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.getList();
            },
            exportData() {
                // console.log("exportData");
            },
        },
        created() {
            this.getList();
        },
    };
</script>
<style lang="scss" scoped>
</style>

