<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                    <el-breadcrumb-item>系统配置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form label-width="150px" ref="form">
            <div class="admin_main_block admin_m15">
                <div class="admin_form_main">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane v-for="(item,index) in list" :key="index" :label="item.name" :name="''+index">
                            <lists :group="item.name" v-if="item.checked"></lists>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import lists from "@/components/admin/system/config/list";

    export default {
        components: {
            lists
        },
        data() {
            return {
                list:'',
                activeName: "0",
                /*定义前一个活动Tab,初始化默认为 ActiveTab */
                oldTab: "0",
            };
        },
        methods: {
            handleClick(tab) {
                this.list[tab.name].checked = true;
                if (tab.name.toString() !== this.oldTab) {
                    this.list[this.oldTab.toString()].checked = false;
                    this.oldTab = tab.name.toString();
                }
            },
            initData(){
                this.$post(
                    this.$api.systemConfigGroup,
                    {}
                ).then((res) => {
                    var list = res.data;
                    if(list){
                        var result = [];
                        list.forEach((item,index) =>{
                            var boolean = false;
                            //默认第一个
                            if(index === 0){
                                boolean = true;
                                this.activeName = index.toString();
                            }
                            result.push({'name':item,'checked':boolean})
                        })
                        this.list = result;
                        console.log(this.list,'*********')
                    }
                });
            }
        },
        created() {
            this.initData();
        },

    };
</script>
<style lang="scss" scoped>
    .el-breadcrumb {
        line-height: 32px;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 146px;
        height: 146px;
        line-height: 146px;
        text-align: center;
    }

    .avatar-upload {
        width: 146px;
        height: 146px;
    }

    .is_master {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        width: 164px;
        text-align: center;
        display: none;
    }

    .goods_class_add_left {
        float: left;
        margin-right: 15px;
        // border:1px solid #e1e1e1;
        padding: 0 20px;
        border-radius: 5px;
        background: #f1f1f1;
    }

    .el-icon-delete {
        cursor: pointer;
    }

    .el-icon-delete:hover {
        color: red;
    }

    .admin_form_main .el-form-item {
        width: 600px;
    }

    .admin_main_block_right {
        width: 280px;
    }
</style>
